body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.companies {
  text-align: center;
}

.companies-title {
  padding-top: 25px;
  font-weight: bold;
}
.companies-title br {
  display: block;
  content: "";
  margin-bottom: -5px; /* Adjust the margin-bottom as needed */
}
.companies-images {
  display: flex;
  justify-content: center;
  align-items: center;
}
.companies-slider-image {
  width: 220px;
  height: auto;
}

.mySwiper {
  width: 75%; 
}

.mySwiper img {
  max-width: 100%;
  max-height: 80px; 
  object-fit: contain;
  margin-left: 80px;
  /* margin: 0 50px;  */
}

.mySwiper img:first-child {
  margin-left: 10px; 
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.topbar-area ul {
  margin-bottom: 0rem !important;
  padding-left: 0rem !important;
}

.blog-img, .blog-btn {
  cursor: pointer;
}

/* Skillbar Styles */
.rs-skillbar.style1 .cl-skill-bar .skillbar-title {
  font-weight: 500;
  font-size: 14px;
  color: #212121;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar {
  background: #eeeeee;
  height: 7px;
  margin: 10px 0 24px;
  position: relative;
  width: 100%;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skillbar-bar {
  background: #106eea;
  height: 7px;
  line-height: 7px;
  background-color: transparent;
  background-image: linear-gradient(190deg, #105aad 0%, #4e95ed 100%);
  background-size: 1rem 1rem;
}
.rs-skillbar.style1 .cl-skill-bar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -38px;
  font-size: 16px;
  font-weight: 700;
  color: #0a0a0a;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar-title {
  text-transform: uppercase;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar {
  background-color: #DFEDFE;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar .skillbar-bar {
  background: #03228f;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skill-bar-percent.white-color {
  color: #fff;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar-title {
  text-transform: uppercase;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar {
  background: #fff;
  border: 1px solid #0B70E1;
  height: 9px;
  border-radius: 0;
  overflow: visible;
  padding: 1px;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar .skillbar-bar {
  background-image: linear-gradient(250deg, #0b70e1 19%, #03228f 100%);
  height: 5px;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skill-bar-percent {
  right: 70px;
  color: #454545;
  font-weight: 400;
}
.rs-skillbar.style1.modify3 {
  padding: 0 120px 0 0;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar {
  height: 8px;
  border: 1px solid #FF571C;
  background-color: transparent;
  border-radius: 3px;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar .skillbar-bar {
  background: #FF571C;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skill-bar-percent {
  right: 70px;
  color: #454545;
  font-weight: 400;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar-title {
  font-weight: 400;
  font-size: 16px;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar {
  border-color: #FF571C;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar .skillbar-bar {
  background: #FF7045;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar {
  background-color: #FD6509;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.paste-bg {
  background-color: #03DEA8;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.blue-bg {
  background-color: #0A99FD;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.pink-bg {
  background-color: #F00674;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.purple-bg {
  background-color: #f006d1;
}

/* Testimonial Styles */
.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}
.rs-testimonial.style8 .testi-item .item-content-basic {
  padding: 0px 28px;
  margin-top: -50px;
  margin-left: -30px;
  font-size: 10px;
}
.rs-testimonial.style8 .testi-item .item-content-basic p {
  font-size: 17px;
  line-height: 32px;
  margin-top: -30px;
  margin-left: -10px;
  font-size: 16px;
  color: #454545;
  font-family: 'Livvic', sans-serif;
    color: #454545;
    font-family: 'Livvic', sans-serif;
}
.rs-testimonial.style6 .testi-item .testi-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  margin-left: -20px;
  margin-top: -40px;
  margin-left: -20px;
}
.rs-testimonial.style6 .testi-item .item-content-basic p {
  margin-top: -20px;
  color: #272525;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 35px;
}

.loading {
  position: absolute;
  text-align: center;
}

.loading img {
  width: 400px;
}