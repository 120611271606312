/* Loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: adds a semi-transparent background */
    z-index: 9999; /* Ensures the loader is on top of other content */
    font-size: 2em; /* Adjust the size of the text */
    color: #3498db; /* Adjust the color of the text */
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.loader-text {
    animation: blink 1.5s infinite;
}
