.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.text-section-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #292929;
}

.text-section-subtitle {
  font-size: 18px;
  color: #6c757d;
}

.text-neutral-4 {
  color: #979797;
}

.section-marker {
  width: 50px;
  height: 4px;
  background-color: #ffc107;
  margin-bottom: 15px;
}

.square-helper {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-color: 3A4443;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.856);
  transition: transform 0.3s ease;
}

.square-helper img{
  width: 100%;
  height: 100%;
}

.square-helper:hover {
  transform: scale(1.05);
}