.bluegreenbg {
  background-color: rgb(2, 48, 88) !important;
}

.mySwiper img {
  max-height: 190px !important;
}

.img-responsive{
  max-width: 100%;
  height: auto;
}